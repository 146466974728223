
import { Component, Vue, Prop, Watch, toNative } from 'vue-facing-decorator';
import { AdvertisementViewModel } from '@/entities/AdvertisementViewModel';
import { newGuid } from '@microsoft/applicationinsights-core-js';
import { Root } from '@/main';
@Component({
  components: {
  },
})
class Advertisement extends Vue {
  @Prop() private advertisementViewModel!: AdvertisementViewModel[];

  private crossIconImage: string = require('@/assets/Images/Remove-icon.svg');
  private addIconImage: string = require('@/assets/Images/Add-icon.svg');
  private addImage: string = require('@/assets/Images/Add-image-icon.svg');
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private localAdvertisementViewModel: AdvertisementViewModel[] = [];
  private regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[aA-zZ\u00a1-\uffff0-9]-*)*[aA-zZ\u00a1-\uffff0-9]+)(?:\.(?:[aA-zZ\u00a1-\uffff0-9]-*)*[aA-zZ\u00a1-\uffff0-9]+)*(?:\.(?:[aA-zZ\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  private disabledAddButton = false;
  private mounted() {
    this.localAdvertisementViewModel = this.advertisementViewModel!;
    const self = this;
    Root.on('showError', (adv: AdvertisementViewModel[]) => {
      self.advertisementViewModel = adv;
      self.localAdvertisementViewModel = adv;
      self.ValidateBannerUrlAdvertisement();
    });
  }
  private CreateNewEmptyAdvertisement() {
    if(this.ValidateBannerUrlAdvertisement()) {
      const blankAdvertismentViewModel =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-var-requires
      new AdvertisementViewModel( newGuid(), '', '','',undefined,
      );
      if(this.advertisementViewModel !== null || this.advertisementViewModel !== undefined) {
        this.advertisementViewModel.push(blankAdvertismentViewModel);
      } else {
        this.advertisementViewModel = this.localAdvertisementViewModel;
        this.advertisementViewModel.push(blankAdvertismentViewModel);
      }
      this.ValidateBannerUrlAdvertisement();
      this.$emit('changeObject');
    }
  }
  private ValidateBannerUrlAdvertisement(): boolean {
    let result = true;
    if(this.advertisementViewModel !== undefined && this.advertisementViewModel !== null) {
      this.advertisementViewModel.forEach((element) => {
        if(element.IsDeleted === false) {
          if(element.BannerUrl === '' || element.BannerUrl === undefined || element.BannerUrl === null) {
            result = false;
            element.IsRequired = true;
            this.disabledAddButton = true;
          }
        }
      });
    }
    if(result) {
      this.disabledAddButton = false;
    }
    return result;
  }
  private showErrorMeesage(element: AdvertisementViewModel): boolean {
    let result = false;
    if(element.BannerUrl === '' || element.BannerUrl === undefined || element.BannerUrl === null) {
      result = true;
    }
    return result;
  }
  private RemoveAdvertisement(index: number) {
    this.advertisementViewModel[index].IsDeleted = true;
    this.$emit('changeObject');
  }
  private ShowImageProcess(index: number) {
    this.$emit('showimageprocess',index);
    this.advertisementViewModel[index].IsRequired = false;
  }
  private onChange(URL: string, index: number) {
    this.$emit('changeObject');
    if(URL !== '' || URL !== undefined) {
      if(this.IsValidURL(URL)) {
        this.localAdvertisementViewModel[index].UrlIsInValid = false;
        this.localAdvertisementViewModel[index].IsRequired = false;
      } else {
        this.localAdvertisementViewModel[index].UrlIsInValid = true;
      }
    } else {
      this.localAdvertisementViewModel[index].UrlIsInValid = false;
      this.localAdvertisementViewModel[index].IsRequired = false;
    }
    this.ValidateBannerUrlAdvertisement();
  }
  // Validate the Url string usign regex
  private IsValidURL(str: string): boolean {
    const regexp = this.regex;
    const result = regexp.test(str);
    return result;
  }
  private GetBannerImage(val: string) {
    if(val !== '' && val!== undefined ){
      if(val.includes('ImageMissing.png')) {
        return this.missingImage;
      } else {
        return val;
      }
    } else {
      return this.addImage;
    }
  }
  private CheckBannerUrl(BannerUrl:  string) {
    if(BannerUrl !== '' && BannerUrl !== undefined && BannerUrl !== null){
      if(BannerUrl.includes('Add-image-icon')) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  private CheckListingHaveValues(): boolean {
    if(this.localAdvertisementViewModel !== null && this.localAdvertisementViewModel !== undefined) {
      if(this.localAdvertisementViewModel.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  @Watch('advertisementViewModel')
  private onPropertyChanged(val: AdvertisementViewModel[]) {
    this.localAdvertisementViewModel = val;
    if(this.localAdvertisementViewModel !== null && this.localAdvertisementViewModel !== undefined) {
      this.localAdvertisementViewModel.forEach((advertisement) => {
        if(advertisement.PageUrl !== '' && advertisement.PageUrl !== undefined && advertisement.PageUrl !== '') {
          if(!this.IsValidURL(advertisement.PageUrl)) {
            advertisement.UrlIsInValid = true;
          }
        } else {
          advertisement.UrlIsInValid = false;
        }
      });
    }
    this.ValidateBannerUrlAdvertisement();
  }
}
export default toNative(Advertisement);
