import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-186cc4f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "self-check-in" }
const _hoisted_2 = { class: "parent-div-self-checkin" }
const _hoisted_3 = { class: "div-heading" }
const _hoisted_4 = { class: "lbl-self-check-in primary-heading" }
const _hoisted_5 = { class: "div-content" }
const _hoisted_6 = ["checked", "disabled"]
const _hoisted_7 = {
  id: "lblSelfCheckInCheckBox",
  class: "lbl-self-check-in-description description"
}
const _hoisted_8 = { class: "div-checkbox" }
const _hoisted_9 = { class: "lbl-self-check-in-descriptionbelow description" }
const _hoisted_10 = { class: "div-tutorial" }
const _hoisted_11 = ["href"]
const _hoisted_12 = ["href"]
const _hoisted_13 = ["title", "disabled"]
const _hoisted_14 = ["src"]
const _hoisted_15 = {
  key: 0,
  class: "warning-message-bar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WarningMessageBar = _resolveComponent("WarningMessageBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("Selfcheck-in")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "div-checkbox",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeSelfCheckInCheckBox()))
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("input", {
              id: "inputSelfCheckInCheckBox",
              type: "checkbox",
              name: "selfCheckIn",
              checked: _ctx.isCheckedselfCheckInCheckBox,
              disabled: !_ctx.reqInfo.IsParticipantSelfCheckInActive
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("EventSetting.SelfcheckInDescription")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("EventSetting.SelfcheckInDescriptionBelow")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("a", {
            target: "_blank",
            href: _ctx.tutorialLink,
            class: "hyper-link description"
          }, _toDisplayString(_ctx.$t("EventSetting.Tutorial")), 9, _hoisted_11),
          (!_ctx.reqInfo.IsParticipantSelfCheckInActive)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                target: "_blank",
                href: _ctx.contactUsToEnableLink,
                class: "hyper-link margin-left-5 description"
              }, _toDisplayString(_ctx.$t("EventVisibility.ContactUsToEnable")), 9, _hoisted_12))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", {
        id: "divEventAccessLinkQRCode",
        title: _ctx.GetQRCodeToolTipMessage(),
        class: _normalizeClass(["div-qrcode div-icon-copied-icon", {'cursor-pointer': (_ctx.localEventAccessLinkQRCodeUrl !== '' || _ctx.localEventAccessLinkQRCodeUrl !== undefined), 'cursor-notallowed': !_ctx.isCheckedselfCheckInCheckBox }]),
        disabled: _ctx.localEventAccessLinkQRCodeUrl === '' || _ctx.localEventAccessLinkQRCodeUrl === undefined,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.OpenImageInPopup($event)))
      }, [
        _createElementVNode("img", {
          id: "imgEventAccessLinkQRCode",
          class: "copied-icon",
          src: _ctx.qrCodeIcon
        }, null, 8, _hoisted_14)
      ], 10, _hoisted_13)
    ]),
    (_ctx.showWarningMessageBar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(_component_WarningMessageBar, {
            "information-view-model": _ctx.selfCheckInInformationMessage,
            onInformationMessageBarCloseOperation: _cache[2] || (_cache[2] = ($event: any) => (
          _ctx.WarningMessageBarCloseOperation()
        ))
          }, null, 8, ["information-view-model"])
        ]))
      : _createCommentVNode("", true)
  ]))
}