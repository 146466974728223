import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16effc51"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "icon div-icon"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text margin-10 width100" }
const _hoisted_4 = { class: "display-flex justify-content-space-between heading margin-bottom-5" }
const _hoisted_5 = {
  key: 0,
  for: "EventVisibility",
  class: "lbl-card no-margin cursor-pointer bold-text"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "checked div-checked-circle" }
const _hoisted_10 = { class: "fa fa-check-circle color-green" }
const _hoisted_11 = { class: "position-relative" }
const _hoisted_12 = ["disabled", "checked"]
const _hoisted_13 = { class: "checked position-relative" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "checked position-relative" }
const _hoisted_16 = {
  for: "enabled",
  class: "position-enabled"
}
const _hoisted_17 = { class: "position-relative" }
const _hoisted_18 = ["disabled", "checked"]
const _hoisted_19 = {
  key: 0,
  class: "small-description margin-bottom-5"
}
const _hoisted_20 = ["value"]
const _hoisted_21 = ["src", "title"]
const _hoisted_22 = {
  key: 1,
  class: "small-description margin-bottom-5"
}
const _hoisted_23 = {
  key: 0,
  class: "read-more"
}
const _hoisted_24 = ["href"]
const _hoisted_25 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-option div-card position-relative", {
      'card-option-selected': _ctx.localCard.IsSelected,
      disable: _ctx.localCard.IsDisabled,
      'display-none': !_ctx.localCard.IsVisible
    }]),
    onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.getReadMore(true))),
    onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.getReadMore(false)))
  }, [
    _createElementVNode("div", {
      class: "display-flex",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectItem(_ctx.localCard)))
    }, [
      (!_ctx.isHideTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("img", {
              src: _ctx.localCard.IconUrl,
              alt: "Warning"
            }, null, 8, _hoisted_2)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            (!_ctx.isHideTitle)
              ? (_openBlock(), _createElementBlock("label", _hoisted_5, _toDisplayString(_ctx.localCard.Title), 1))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.localCard.IconUrl,
                  class: "logo"
                }, null, 8, _hoisted_6))
          ]),
          (_ctx.localCard.ControlType === 4 && _ctx.localCard.IsSelected)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("img", {
                  src: _ctx.GetIconImage(),
                  class: "margin-right-10"
                }, null, 8, _hoisted_7),
                _createElementVNode("label", {
                  class: _normalizeClass(["no-margin font-weight-500 valign", [_ctx.IsInputBoxVisible() && _ctx.isCallbackReturned ? 'successfully-configured' : (_ctx.IsInputBoxVisible() ? 'warning-message' : 'not-configured') ]])
                }, _toDisplayString(_ctx.localCard.Message), 3),
                (!_ctx.IsInputBoxVisible())
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      class: "not-configured font-weight-600 valign",
                      href: _ctx.localCard.ReadMoreLink,
                      target: "_blank"
                    }, _toDisplayString(_ctx.$t('EventVisibility.ReadMore')), 9, _hoisted_8))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("i", _hoisted_10, null, 512), [
              [_vShow, _ctx.localCard.IsSelected]
            ])
          ], 512), [
            [_vShow, _ctx.localCard.ControlType === 0]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_11, [
            _createElementVNode("input", {
              type: "checkbox",
              name: "featureCheckBox",
              class: "checked-position cursor-pointer",
              disabled: _ctx.localCard.IsDisabled,
              checked: _ctx.localCard.IsSelected && !_ctx.localCard.IsDisabled
            }, null, 8, _hoisted_12)
          ], 512), [
            [_vShow, _ctx.localCard.ControlType === 1]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_13, [
            _createElementVNode("a", {
              href: _ctx.contactUsToEnableLink,
              target: "_blank",
              class: "hyper-link position-contact-us-to-enabled"
            }, _toDisplayString(_ctx.$t("EventVisibility.ContactUsToEnable")), 9, _hoisted_14)
          ], 512), [
            [_vShow, _ctx.localCard.ControlType === 2]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("Enabled")), 1)
          ], 512), [
            [_vShow, _ctx.localCard.ControlType === 3]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_17, [
            _createElementVNode("input", {
              type: "checkbox",
              name: "integrationCheckBox",
              class: "checked-position cursor-pointer",
              disabled: _ctx.localCard.IsDisabled,
              checked: _ctx.localCard.IsSelected && !_ctx.localCard.IsDisabled
            }, null, 8, _hoisted_18)
          ], 512), [
            [_vShow, _ctx.localCard.ControlType === 4]
          ])
        ]),
        (_ctx.IsDesciptionHidden())
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('APIkey')), 1),
              _createElementVNode("div", {
                class: "display-flex",
                onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("input", {
                  type: "text",
                  class: "form-control",
                  readonly: "",
                  value: _ctx.localCard.InputBoxData
                }, null, 8, _hoisted_20),
                _createElementVNode("img", {
                  src: _ctx.copyIcon,
                  class: "margin-left-10",
                  title: _ctx.$t('Dialogue.CopyIcon'),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.CopyKeyToClipboard(_ctx.localCard.InputBoxData)))
                }, null, 8, _hoisted_21)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.localCard.Description), 1))
      ])
    ]),
    (_ctx.isReadMoreVisible && _ctx.localCard.HelpText !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
          _createElementVNode("a", {
            href: _ctx.localCard.HelpTextUrl,
            target: "_blank",
            class: "text-underline margin-right-3"
          }, _toDisplayString(_ctx.localCard.HelpText), 9, _hoisted_24),
          (_ctx.localCard.IsExternalLink)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.readMoreIcon,
                alt: "read more icon"
              }, null, 8, _hoisted_25))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 34))
}