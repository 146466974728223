import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-564e072a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "participant-qr" }
const _hoisted_2 = { class: "parent-div-participant-qr" }
const _hoisted_3 = { class: "div-heading" }
const _hoisted_4 = { class: "lbl-participant-qr primary-heading" }
const _hoisted_5 = { class: "div-content" }
const _hoisted_6 = ["checked"]
const _hoisted_7 = {
  id: "lblParticipantTicketQR",
  class: "lbl-participant-qr-description description"
}
const _hoisted_8 = { class: "div-checkbox" }
const _hoisted_9 = { class: "lbl-participant-qr-descriptionbelow description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("EventSetting.ParticipantQRTickets")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "div-checkbox",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ChangeParticipantQRCheckBoxValue()))
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("input", {
              id: "inputParticipantTicketQR",
              type: "checkbox",
              name: "participantQR",
              checked: _ctx.localIsParticipantQRVisible
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("EventSetting.ShowParticipantUniqueQRAndTicketsInAppMenu")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("EventSetting.ParticipantQRTicketsDescription")), 1)
          ])
        ])
      ])
    ])
  ]))
}