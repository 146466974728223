import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94b3989a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "eventsetting create-new-event-setting"
}
const _hoisted_3 = {
  key: 1,
  class: "event-setting-child-tab"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateNewEvent = _resolveComponent("CreateNewEvent")!
  const _component_EventSettingTabs = _resolveComponent("EventSettingTabs")!

  return (_ctx.ShowEventSettingsPage())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showCreateNew())
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_CreateNewEvent)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.showCreateNew())
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_EventSettingTabs)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}