import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ffea994"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs-event-setting" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!
  const _component_Toaster = _resolveComponent("Toaster")!
  const _component_loading = _resolveComponent("loading")!
  const _component_VueTabs = _resolveComponent("VueTabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showVueModalPopUp)
      ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
          key: 0,
          "modal-pop-up": _ctx.unsavedModalPopUp,
          onClose: _ctx.CloseModelPopup,
          onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SaveModelPopUp($event)))
        }, null, 8, ["modal-pop-up", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showToaster)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Toaster, {
            message: _ctx.toasterMessage,
            "default-timeout": _ctx.toasterDefaultTimeout
          }, null, 8, ["message", "default-timeout"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoader,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    _createVNode(_component_VueTabs, {
      "tab-view-model": _ctx.eventSettingTabEntityList,
      onSelectedTab: _ctx.selectedTab
    }, null, 8, ["tab-view-model", "onSelectedTab"])
  ]))
}